body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none; /* Disable selection in Webkit browsers */
  -moz-user-select: none; /* Disable selection in Mozilla Firefox */
  -ms-user-select: none; /* Disable selection in Microsoft Edge */
  user-select: none; /* Disable selection in standard browsers */
  overscroll-behavior-x: none;
  zoom: 1;

  background-image: url("./assets/img/background.png");
  background-size: cover;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
