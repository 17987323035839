.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 40px;
  margin-bottom: 0;
}

.logo {
  width: 137px;
  height: 77px;
  flex-shrink: 0;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.languageButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background-color: #dedbbd;
  background-color: #f5f4e9;
  display: inline-flex;
  padding: 4px;
  align-items: flex-start;
  gap: 4px;
}

.languageButton {
  display: flex;
  width: 73px;
  padding: 5px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background-color: transparent;
  border: none;
  color: #908622;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
  cursor: pointer;
  /* pointer-events: none; */
}

.activeLanguageButton {
  background-color: #908622 !important;
  color: #fff;
}

.activeGenerateButton {
  background-color: #908622 !important;
  cursor: pointer;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 459px;
  gap: 16px;
  margin: 56px 0;
  margin-bottom: 64px;
}

.input {
  padding: 16px 24px;
  border-radius: 4px;
  background-color: #f5f4e9;
  font-family: "Roboto", sans-serif;
  color: #908622;
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  border: 2px solid #908622;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #908622;
}

.generateButton {
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #cccab9;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  border: none;
  /* pointer-events: none; */
}

.quoteContainer {
  width: 610px;
  padding: 56px 24px;
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  border-top: 2px solid #dedbbd;
}

.welcomeText {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 32px;
}

.welcomeText p {
  margin: 0;
}

.quote {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}

.fade-in {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

.fade-out {
  animation: fadeOut 5s;
  -webkit-animation: fadeOut 5s;
  -moz-animation: fadeOut 5s;
  -o-animation: fadeOut 5s;
  -ms-animation: fadeOut 5s;
}

.fade-in.fade-in-active {
  opacity: 1;
}

.fade-out.fade-out-active {
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
